import PropTypes from 'prop-types'
import React from 'react'
import pic01 from '../images/regenerativefarming.jpg'
import pic03 from '../images/gab-1.jpg'

class Main extends React.Component {
  render() {
    let close = (
      <div
        className="close"
        onClick={() => {
          this.props.onCloseArticle()
        }}
      ></div>
    )

    return (
      <div
        ref={this.props.setWrapperRef}
        id="main"
        style={this.props.timeout ? { display: 'flex' } : { display: 'none' }}
      >
        <article
          id="intro"
          className={`${this.props.article === 'intro' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Regenerative Farming</h2>
          <span className="image main">
            <img src={pic01} alt="" />
          </span>
          <p>
            Regenerative agriculture is a conservation and rehabilitation approach 
            to food and farming systems. It focuses on topsoil regeneration, increasing 
            biodiversity, improving the water cycle, enhancing ecosystem services, 
            supporting biosequestration, increasing resilience to climate change, 
            and strengthening the health and vitality of farm soil.</p>
          <p>
            Regenerative agriculture is not a specific practice itself. Rather, 
            proponents of regenerative agriculture use a variety of sustainable 
            agriculture techniques in combination.[4] Practices include recycling as 
            much farm waste as possible and adding composted material from sources 
            outside the farm. Regenerative agriculture on small farms and gardens is 
            often based on philosophies like permaculture, agroecology, agroforestry, 
            restoration ecology, keyline design, and holistic management. Large farms 
            tend to be less philosophy driven and often use "no-till" and/or "reduced till"
            practices.  As soil health improves, input requirements may decrease, and 
            crop yields may increase as soils are more resilient against extreme weather
            and harbor fewer pests and pathogens.</p>
          <p>
            Most plans to mitigate climate change focus on "reducing greenhouse gas emissions."
            Regenerative agriculture, i.e. the capture of atmospheric carbon dioxide by 
            growing plants that move that carbon dioxide into the soil, is pretty nearly
            the only currently-functioning technology available for drawing down greenhouse
            gases that are already in the atmosphere, mostly through the cultivation and 
            nurturing of forests and permanent perennial pastures and grasslands.
          </p>
          {close}
        </article>

        <article
          id="about"
          className={`${this.props.article === 'about' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">About</h2>
          <span className="image main">
            <img src={pic03} alt="" />
          </span>
          <p>
            Crow About Farms is located on 20 acres in Coryell County, just west of Gatesville,
            TX.  By applying regenerative farming techniques, we intend to bring this patch of 
            ehausted farmland back to its original food producing glory. Our goal is to become
            more self sufficient in terms of our food supply both in crops and cattle.  To
            paraphrase a Texas favorite, Blue Bell Ice Cream, with what we raise here, we plan
            "to eat all we can, and sell the rest."</p>
          <p>
            We curently have 12 egg producing hens and one Rooster (named 
            <a href="https://en.wikipedia.org/wiki/Bertie_Wooster" target="_blank">Birdie Rooster</a>).
            They produce on average 8 eggs per day, so we end up giving extras to friends and family.
            Later in 2022, we will start bringing cows onto the property to begin intensive rotational
            grazing which is key to soil regeneration.
          </p>
          {close}
        </article>

        <article
          id="contact"
          className={`${this.props.article === 'contact' ? 'active' : ''} ${
            this.props.articleTimeout ? 'timeout' : ''
          }`}
          style={{ display: 'none' }}
        >
          <h2 className="major">Contact</h2>
          <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" />
              </li>
              <li>
                <input type="reset" value="Reset" />
              </li>
            </ul>
          </form>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main
